@tailwind base;
/* This is all you. */
@layer base {
  h1 {
    @apply text-7xl font-bold italic leading-18;
  }

  h2 {
    @apply text-6xl font-bold italic leading-16;
  }

  h3 {
    @apply text-4xl font-bold leading-12;
  }

  h4 {
    @apply text-3xxl font-semibold leading-10;
  }

  h5 {
    @apply text-2xl leading-8;
  }

  p {
    @apply text-base font-normal leading-6;
  }
}
@media only screen and (max-width: 1439px) {
  h1 {
    font-size: 3.875rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  h4 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  p {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }

  h2 {
    font-size: 2.125rem;
    line-height: 2.5rem;
  }
}
@media only screen and (min-width: 1920px) {
  .signUp {
    display: block !important;
    display: inline-flex !important;
  }
}
.btn {
  @apply px-4 rounded;
}

.btn-secondary {
  @apply bg-secondary-500 text-llg lg:text-xl text-primary-500 rounded-3xl leading-6 py-3;
}

.btn-primary {
  @apply bg-primary-500 text-llg lg:text-xl text-white rounded-3xl leading-6 py-3;
}

.btn-input {
  @apply bg-white border border-gray-300 rounded-3xl leading-6 py-3 text-base;
}

.btn-help {
  @apply bg-white text-llg lg:text-xl text-primary-500 border border-primary-200 rounded-3xl leading-6 py-3;
}

.btn-tag {
  @apply bg-secondary-200 rounded-3xl text-base text-primary-500 leading-6;
}

.btn-tag:hover {
  @apply underline bg-secondary-500;
}

.navHover {
  @apply rounded-3xl px-2 py-3;
}

.navHover:hover {
  text-decoration: underline;
  background-color: rgba(0, 0, 0, 0.1);
}

.feature {
  cursor: pointer;
  @apply rounded-3xl px-2 py-3 text-md lg:text-llg leading-6;
}

.feature:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.feature:hover span {
  text-decoration: underline;
  text-decoration-color: #082659;
}

.hover-blog {
  @apply rounded-3xl px-2 py-3 text-md lg:text-llg leading-6;
}

.hover-blog:hover {
  background-color: rgba(0, 0, 0, 0.05);
  text-decoration: underline;
  text-decoration-color: #0c886d;
  cursor: pointer;
}

.usage {
  @apply rounded-2xl px-2 py-1 text-md text-base leading-6;
}

.usage:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dropdownShadow {
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.iconBackground {
  background-color: rgba(0, 0, 0, 0.1);
}

.purpose {
  @apply rounded-3xl px-2 py-1;
}

.purpose:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.subnavi {
  @apply rounded-3xl px-4 py-3;
}

.subnavi:hover {
  background-color: rgba(0, 0, 0, 0.05);
  text-decoration: underline;
}

.mobile-subnavi:hover {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: underline;
}

.settings2 {
  display: none;
}

.settings:hover .settings2 {
  display: block;
}

.settings:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.settings:hover .billing {
  text-decoration: underline;
}

.settingsAlert {
  display: none;
}

.settingsNav:hover .settingsAlert {
  display: block;
}

.select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
}

.select {
  position: relative;
  display: flex;
  flex-direction: column;
}

.select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  transition: all 0.5s;
}

.select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  cursor: pointer;
  transition: all 0.5s;
}

.custom-option:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

.border-select {
  border: solid 1px;
  border-color: rgba(255, 255, 255, 0);
}

.devSubmenu:hover h5 {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.devSubmenu:hover p {
  text-decoration: underline;
}

.dashboard-slidedown {
  visibility: hidden;
  -webkit-transform: translateX(100vw);
  transform: translateX(100vw);
  -webkit-transition: -webkit-transform 0.3s ease-in-out, visibility 0s 0.3s;
  transition: transform 0.3s ease-in-out, visibility 0s 0.3s;
}
.dashboard-slidedown.open {
  visibility: visible;
  -webkit-transform: translateX(calc(100vw - 208px));
  transform: translateX(calc(100vw - 208px));
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}

.dashboard-overlay {
  position: absolute;
  top: 64px;
}

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.shadowForm {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14);
  width: 100%;
}

@tailwind components;
@tailwind utilities;